import { useTranslation } from "react-i18next";
import "./App.css";
import Home from "./Pages/Home/Home";
import "./i18n/i18n";

function App() {
  const { t, i18n } = useTranslation();
  document.body.dir = i18n.dir();
  return (
    <div className="App">
      <Home />
    </div>
  );
}

export default App;
