import React from "react";

function Content() {
  return (
    <div style={{ color: "black" }}>
      <h1>This is heading 1</h1>
      <h2>This is heading 2</h2>
      <h3>This is heading 3</h3>
      <p>This is a paragraph.</p>
      <p>This is another paragraph.</p>
      <a href="https://www.w3schools.com">This is a link</a>
      <img src="w3schools.jpg" alt="W3Schools.com" width="104" height="142" />
      <table>
        <tr>
          <th>Company</th>
          <th>Contact</th>
          <th>Country</th>
        </tr>
        <tr>
          <td>Alfreds Futterkiste</td>
          <td>Maria Anders</td>
          <td>Germany</td>
        </tr>
        <tr>
          <td>Centro comercial Moctezuma</td>
          <td>Francisco Chang</td>
          <td>Mexico</td>
        </tr>
      </table>
    </div>
  );
}

export default Content;
