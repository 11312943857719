import React, { useRef, useState } from "react";
import { Button, IconButton, Typography, Grid, Box } from "@mui/material";

import { useTranslation } from "react-i18next";
import Content from "../../components/Content/Content";
import ButtonIcon from "../../assets/img/up.png";
import "./Home.css";
const Widget = React.lazy(() => import("../../components/Widget/Widget"));

function Home() {
  const { t } = useTranslation();
  const [showMenu, setShowMenu] = useState(false);
  return (
    <main className="content">
      <Button
        // className="Widget-btn"
        variant="outlined"
        // color="primary"
        aria-label="Open "
        style={{
          position: "fixed",
          bottom: "4em",

          right: "5em",
          maxidth: "100px",
          // borderRadius: "20%",
          border: "none",
          maxWidth: "80px",
        }}
        onClick={() => {
          setShowMenu(!showMenu);
        }}
      >
        <img
          src={ButtonIcon}
          alt="A11y Menu"
          style={{ maxWidth: "100%", with: "100px" }}
        />
      </Button>

      <Widget show={showMenu} />
      <Content />
    </main>
  );
}

export default Home;
